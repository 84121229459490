import React, { useState, useEffect } from 'react'
import { startOfDay, endOfDay, subDays } from 'date-fns'
import '../features/dashboard/dashboard.css'
import TestsCompleted from '../features/dashboard/widgets/testsCompleted'
import RunningTests from '../features/dashboard/widgets/runningTests'
import PercentageCompleted from '../features/dashboard/widgets/percentageCompleted'
import TotalCandidates from '../features/dashboard/widgets/totalCandidates'
import TestsCompletedCharts from '../features/dashboard/charts/testsCompletedChart'
import DatePickerFilter from '../features/dashboard/datePickerFilter'
import CircularIndeterminate from '../features/circularIndeterminate/circularIndeterminate'
import HttpRequests from '../api/httpRequests'
import SearchIcon from '@material-ui/icons/Search'
import { convertToTimestampMidnight } from '../utils/utilFunctions'
import { FiDelete } from 'react-icons/fi'

export default function Dashboard() {
  const [loading, setLoading] = useState(true)
  const [dashboardData, setDashboardData] = useState(null)
  const [startDateFilter, setStartDateFilter] = useState(
    startOfDay(subDays(new Date(), 7))
  )
  const [endDateFilter, setEndDateFilter] = useState(endOfDay(new Date()))

  const getContents = async () => {
    try {
      const responseDashboardData = await HttpRequests.retrieveDashboard({
        start_date: convertToTimestampMidnight(startDateFilter, true),
        end_date: convertToTimestampMidnight(endDateFilter, false)
      })
      setDashboardData(responseDashboardData.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleSearch = async () => {
    if (loading) return
    let parameters = {
      start_date:
        startDateFilter === null
          ? null
          : convertToTimestampMidnight(startDateFilter, true),
      end_date:
        endDateFilter === null
          ? null
          : convertToTimestampMidnight(endDateFilter, false)
    }

    // Verify if the dates interval is more than 30 days
    if (parameters.start_date && parameters.end_date) {
      if (parameters.end_date - parameters.start_date > 2678400) {
        alert('O intervalo de datas não pode ser maior que 30 dias.')
        setLoading(false)
        return
      }
    }
    setLoading(true)
    const responseDashboardData = await HttpRequests.retrieveDashboard(
      parameters
    )
    setDashboardData(responseDashboardData.data.data)
    setLoading(false)
  }

  const clearFilters = () => {
    if (loading) return
    setLoading(true)
    setStartDateFilter(null)
    setEndDateFilter(null)
    setLoading(false)
  }

  const handleStartDateFilter = e => {
    setStartDateFilter(e)
  }

  const handleEndDateFilter = e => {
    setEndDateFilter(e)
  }

  useEffect(() => {
    if (dashboardData) {
      setLoading(false)
    }
  }, [dashboardData])

  useEffect(() => {
    getContents()
  }, [])

  return (
    <div>
      <h1>Dashboard</h1>
      <div className='row mt-5'>
        <div className='col-lg-12 column-lmd-2-half column-md-2-full'>
          <div className='icon-box d-flex align-items-center'>
            <div className='filters-area'>
              <DatePickerFilter
                label='Data inicial'
                value={startDateFilter}
                handleStartDate={handleStartDateFilter}
                maxDate={endDateFilter}
                disabled={loading}
              />
              <DatePickerFilter
                label='Data Final'
                value={endDateFilter}
                handleStartDate={handleEndDateFilter}
                minDate={startDateFilter}
                disabled={loading}
              />
              <span className='filter-search' onClick={() => handleSearch()}>
                <SearchIcon fontSize='large' />
              </span>
              <FiDelete
                className='clear-filters'
                size='2em'
                onClick={() => clearFilters()}
              />
            </div>
          </div>
        </div>
      </div>
      {!loading && dashboardData ? (
        <>
          <div className='row mt-3'>
            <TotalCandidates data={dashboardData.candidates_amount} />
            <TestsCompleted data={dashboardData.quizzes_done_amount} />
            <RunningTests data={dashboardData.quizzes_applied_amount} />
            <PercentageCompleted data={dashboardData.quizzes_done_percent} />
          </div>
          <div className='row'>
            <div className='col-lg-12 column-lmd-2-half column-md-full'>
              <TestsCompletedCharts data={dashboardData.quizzes_done_per_day} />
            </div>
          </div>
        </>
      ) : (
        <>
          <CircularIndeterminate />
        </>
      )}
    </div>
  )
}
