import React from 'react'
import DateFnsUtils from '@date-io/date-fns' // choose your lib
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'

export default function DatePickerFilter(props) {
  const handleChange = e => {
    if (e == 'Invalid Date') {
      props.handleStartDate(new Date())
    } else {
      props.handleStartDate(e)
    }
  }

  return (
    <>
      <div className='input-box'>
        <label className='label-text'>{props.label}</label>
        <div className='form-group'>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disabled={props.disabled}
              autoOk={true}
              clearable
              value={props.value}
              placeholder=''
              onChange={e => handleChange(e)}
              format='dd/MM/yyyy'
              minDate={props.minDate}
              maxDate={props.maxDate}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </>
  )
}
