import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Message from '../utils/messages/message'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import InputText from '../features/inputText/inputText'
import SelectInput from '../features/selectInput/selectInput'
import TextAreaEditableJodit from '../features/textAreaEditable/textAreaEditableJodit'
import InputDatePickerTime from '../features/inputDatePickerTime/inputDatePickerTime'
import '../features/editQuiz/editQuiz.css'
import { convertToTimestamp } from '../utils/utilFunctions'
import HttpRequests from '../api/httpRequests'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { ActivatePreloader } from '../template/preloader'

function EditQuiz() {
  const [isLoading, setIsLoading] = useState(true)
  const [msgType, setMsgType] = useState('')
  const [quizData, setQuizData] = useState(null)
  const [groupsData, setGroupsData] = useState(null)
  const [quizStatus, setQuizStatus] = useState(null)
  const [openWarning, setOpenWarning] = useState(false)
  let history = useHistory()
  let { uuid } = useParams()

  const retrieveContents = async () => {
    try {
      const responseQuiz = await HttpRequests.retrieveQuiz(uuid)
      const responseGroups = await HttpRequests.listGroups({ per_page: 100 })

      setQuizData(responseQuiz.data.data)
      setGroupsData(responseGroups.data.data)
      setQuizStatus(responseQuiz.data.data.status)
      setIsLoading(false)
    } catch {
      setMsgType('500')
    }
  }

  const updateQuizStatus = async value => {
    setIsLoading(true)
    const response = await HttpRequests.patchStatus(
      {
        status: value
      },
      uuid
    )
    switch (response.status_code) {
      case 200:
        setQuizStatus(value)
        setMsgType('update-success')
        break
      case 423:
        setMsgType('423')
        break
      default:
        setMsgType('500')
        break
    }
    setIsLoading(false)
  }
  const handleWarningClose = () => {
    setOpenWarning(false)
    window.$('.sort-ordering-select-no-search').selectpicker('refresh')
  }

  const handleWarningConfirm = () => {
    updateQuizStatus('archived')
    setOpenWarning(false)
  }

  const handleStatus = async e => {
    if (e.target.value != 'archived') {
      updateQuizStatus(e.target.value)
    } else {
      setOpenWarning(true)
    }
  }

  const handleNumberAdditionalFields = e => {
    const numberAdditionalFields = e.target.value
    let newAddtionalFields = []
    if (numberAdditionalFields > 0) {
      for (let i = 0; i < numberAdditionalFields; i++) {
        newAddtionalFields.push({
          id: '',
          label: '',
          instructions: '',
          required: '',
          regex_validation: '',
          register_validation: false,
          integration_field: 'false',
          status: ''
        })
      }
    }

    if (numberAdditionalFields === '0') {
      newAddtionalFields.length = 0
    }

    setQuizData({
      ...quizData,
      additional_fields: newAddtionalFields
    })

    formik.setValues({
      ...formik.values,
      addtionalFields: newAddtionalFields
    })
    formik.handleChange({
      target: {
        name: 'numberAdditionalFields',
        value: numberAdditionalFields
      }
    })
  }

  const onSubmit = async fields => {
    let additionalFieldsSubmit = []
    let additionalFieldsChanged = false

    //Add or delete addtional fields
    if (quizData.additional_fields.length !== fields.additionalFields.length) {
      additionalFieldsChanged = true
    }

    //Edited any addtional fields input
    if (
      quizData.additional_fields.length === fields.additionalFields.length &&
      fields.additionalFields.length > 0
    ) {
      fields.additionalFields.map((additionalFields, i) => {
        if (additionalFields.id !== quizData.additional_fields[i].id) {
          additionalFieldsChanged = true
        }

        if (
          additionalFields.required !== quizData.additional_fields[i].required
        ) {
          additionalFieldsChanged = true
        }

        if (
          additionalFields.register_validation !==
          quizData.additional_fields[i].register_validation
        ) {
          additionalFieldsChanged = true
        }

        if (additionalFields.label !== quizData.additional_fields[i].label) {
          additionalFieldsChanged = true
        }

        if (
          additionalFields.instructions !==
          quizData.additional_fields[i].instructions
        ) {
          additionalFieldsChanged = true
        }

        if (
          additionalFields.regex_validation !==
          quizData.additional_fields[i].regex_validation
        ) {
          additionalFieldsChanged = true
        }

        if (
          additionalFields.integration_field !==
          quizData.additional_fields[i].integration_field
        ) {
          additionalFieldsChanged = true
        }

        if (additionalFields.status !== quizData.additional_fields[i].status) {
          additionalFieldsChanged = true
        }
      })
    }

    if (fields.additionalFields.length > 0) {
      fields.additionalFields.map((additionalFields, i) => {
        additionalFieldsSubmit[i] = {
          id: additionalFields.id,
          required: additionalFields.required,
          register_validation: additionalFields.register_validation
        }

        if (additionalFields.label !== '') {
          additionalFieldsSubmit[i] = {
            ...additionalFieldsSubmit[i],
            label: additionalFields.label
          }
        }

        if (additionalFields.instructions !== '') {
          additionalFieldsSubmit[i] = {
            ...additionalFieldsSubmit[i],
            instructions: additionalFields.instructions
          }
        }

        if (additionalFields.regex_validation !== '') {
          additionalFieldsSubmit[i] = {
            ...additionalFieldsSubmit[i],
            regex_validation: additionalFields.regex_validation
          }
        }

        if (additionalFields.integration_field !== '') {
          additionalFieldsSubmit[i] = {
            ...additionalFieldsSubmit[i],
            integration_field: additionalFields.integration_field
          }
        }

        if (additionalFields.status !== '') {
          additionalFieldsSubmit[i] = {
            ...additionalFieldsSubmit[i],
            status: additionalFields.status
          }
        }
      })
    }
    let startDate = fields.startDate
    if (
      startDate !== null &&
      startDate !== '' &&
      !Number.isInteger(startDate)
    ) {
      startDate = convertToTimestamp(startDate)
    }

    let endDate = fields.endDate
    if (endDate !== null && endDate !== '' && !Number.isInteger(endDate)) {
      endDate = convertToTimestamp(endDate)
    }

    let data = {
      title: fields.title,
      instructions: fields.instructions !== '' ? fields.instructions : null,
      final_message: fields.finalMessage !== '' ? fields.finalMessage : null,
      lang: fields.lang !== '' ? fields.lang : null,
      start_date: fields.startDate !== '' ? startDate : null,
      end_date: fields.endDate !== '' ? endDate : null,
      order_type: fields.orderType !== '' ? fields.orderType : '',
      show_results: fields.showResults !== '' ? fields.showResults : null,
      quiz_type: fields.quizType !== '' ? fields.quizType : null,
      group: fields.group !== '' ? fields.group : null,
      reapply_time:
        fields.reapplyTime === null || fields.reapplyTime === ''
          ? null
          : fields.reapplyTime * 86400
    }

    if (
      fields.additionalFields.length > 0 &&
      additionalFieldsChanged === true
    ) {
      data = { ...data, additional_fields: additionalFieldsSubmit }
    }

    if (fields.externalIds !== '') {
      data = { ...data, external_ids: JSON.parse(fields.externalIds) }
    }

    const response = await HttpRequests.patchQuiz(data, uuid)

    switch (response.status_code) {
      case 200:
        history.push('/quizzes')
        break
      case 423:
        setMsgType('423')
        break
      default:
        setMsgType('500')
        break
    }
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      instructions: '',
      finalMessage: '',
      lang: '',
      startDate: null,
      endDate: null,
      orderType: '',
      showResults: true,
      additionalFields: [],
      externalIds: '',
      quizType: '',
      group: { name: '', uuid: '' },
      numberAdditionalFields: 0,
      reapplyTime: null
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .min(1, 'Deve ter entre 1 e 80 caracteres')
        .max(80, 'Deve ter entre 1 e 80 caracteres')
        .required('Campo obrigatório'),
      instructions: Yup.string()
        .nullable()
        .min(1, 'Deve ter entre 1 e 4000 caracteres')
        .max(4000, 'Deve ter entre 1 e 4000 caracteres'),
      finalMessage: Yup.string()
        .nullable()
        .min(1, 'Deve ter entre 1 e 1000 caracteres')
        .max(2000, 'Deve ter entre 1 e 2000 caracteres'),
      lang: Yup.string()
        .min(5, 'Deve ter 5 caracteres')
        .max(5, 'Deve ter 5 caracteres')
        .required('Campo obrigatório'),
      startDate: Yup.date()
        .nullable()
        .default(() => new Date()),
      endDate: Yup.date()
        .nullable()
        .default(() => null)
        .when('startDate', (startDate, schema) => {
          if (startDate != 'Invalid Date') {
            return schema.min(
              startDate,
              'A data de término deve ser maior que a data de início'
            )
          } else {
            return schema.min(
              new Date(),
              'A data de término deve ser maior que a data de hoje'
            )
          }
        }),
      orderType: Yup.string()
        .max(10, 'Deve ter no máximo 10 caracteres')
        .required('Campo obrigatório'),
      showResults: Yup.string().required('Campo obrigatório'),
      additionalFields: Yup.array().of(
        Yup.object().shape({
          id: Yup.string()
            .matches('^[0-9a-zA-Z_-]+$', 'Não é permitido espaço ou caracteres')
            .required('Campo obrigatório'),
          label: Yup.string()
            .min(1, 'Deve ter entre 1 e 60 caracteres')
            .max(60, 'Deve ter entre 1 e 60 caracteres'),
          instructions: Yup.string().max(
            60,
            'Deve ter no máximo 60 caracteres'
          ),
          required: Yup.string().required('Campo obrigatório'),
          regex_validation: Yup.string().max(
            255,
            'Deve ter no máximo 255 caracteres'
          ),
          register_validation: Yup.string().required('Campo obrigatório'),
          status: Yup.string().required('Campo obrigatório'),
          integration_field: Yup.string().required('Campo obrigatório')
        })
      ),
      externalIds: Yup.string()
        .nullable()
        .min(1, 'Deve ter entre 1 e 100 caracteres')
        .max(100, 'Deve ter entre 1 e 100 caracteres'),
      quizType: Yup.string().required('Campo obrigatório'),
      group: Yup.string().required('Campo obrigatório'),
      reapplyTime: Yup.number()
        .positive()
        .integer()
        .min(1, 'Deve ter no mínimo 1 dia')
        .nullable()
    }),
    onSubmit: onSubmit
  })

  useEffect(() => {
    if (quizData) {
      formik.setValues({
        title: quizData.title || '',
        instructions: quizData.instructions || '',
        finalMessage: quizData.final_message || '',
        lang: quizData.lang || '',
        startDate: quizData.start_date || '',
        endDate: quizData.end_date || '',
        orderType: quizData.order_type || '',
        showResults: quizData.show_results.toString() || '',
        additionalFields: quizData.additional_fields
          ? quizData.additional_fields.map(additional_field => ({
              ...additional_field,
              integration_field:
                additional_field.integration_field === undefined
                  ? false
                  : additional_field.integration_field,
              status:
                additional_field.status === undefined
                  ? 'active'
                  : additional_field.status
            }))
          : [],
        externalIds: JSON.stringify(quizData.external_ids) || '',
        quizType: quizData.quiz_type || '',
        group: quizData.group ? quizData.group.uuid : '',
        numberAdditionalFields: quizData.additional_fields
          ? quizData.additional_fields.length
          : 0,
        reapplyTime:
          quizData.reapply_time !== null ? quizData.reapply_time / 86400 : null
      })
    }
  }, [quizData])

  useEffect(() => {
    retrieveContents()
  }, [])

  return (
    <>
      <ActivatePreloader show={isLoading} />
      {!isLoading && (
        <>
          <form onSubmit={formik.handleSubmit}>
            <div className='row mt-5'>
              <div className='col-lg-12'>
                <Message msgType={msgType} />
                <div className='card-box-shared'>
                  <div className='card-box-shared-title'>
                    <h3 className='widget-title'>Editar Prova</h3>
                  </div>
                  <div className='card-box-shared-body'>
                    <div className='user-form'>
                      <div className='contact-form-action'>
                        <div className='row'>
                          <div className='col-6'>
                            <div className='input-dates'>
                              <InputDatePickerTime
                                name='startDate'
                                label='Data de início'
                                initialDate={
                                  formik.values.startDate !== ''
                                    ? formik.values.startDate
                                    : null
                                }
                                value={formik.values.startDate}
                                onChange={formik.handleChange}
                                error={formik.errors.startDate}
                              />
                              <InputDatePickerTime
                                name='endDate'
                                label='Data de término'
                                initialDate={
                                  formik.values.endDate !== ''
                                    ? formik.values.endDate
                                    : null
                                }
                                value={formik.values.endDate}
                                onChange={formik.handleChange}
                                error={formik.errors.endDate}
                              />
                            </div>
                          </div>
                          <div className='col-3'>
                            <SelectInput
                              value={quizStatus}
                              label='Status'
                              name='status'
                              className='sort-ordering-select-no-search'
                              onChange={handleStatus}
                            >
                              <option value='published'>Publicado</option>
                              <option value='unpublished'>Não publicado</option>
                              <option value='in_testing'>Em teste</option>
                              <option value='archived'>Arquivado</option>
                            </SelectInput>
                          </div>
                          <div className='col-3'>
                            <InputText
                              label='Período para reaplicação'
                              name='reapplyTime'
                              value={formik.values.reapplyTime}
                              onChange={formik.handleChange}
                              error={formik.errors.reapplyTime}
                              type='number'
                              popoverHelp='Informe o período de dias onde será possível o candidato se reaplicar nesta prova.'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12'>
                            <InputText
                              label='Título'
                              name='title'
                              value={formik.values.title}
                              onChange={formik.handleChange}
                              error={formik.errors.title}
                              type='text'
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 col-md-6 col-lg-4'>
                            <SelectInput
                              label='Grupo'
                              name='group'
                              className='sort-ordering-select-no-search'
                              value={formik.values.group}
                              onChange={formik.handleChange}
                              error={formik.errors.group}
                            >
                              <option value=''>Selecione</option>
                              {groupsData.map(group => (
                                <option
                                  key={`${group.uuid}`}
                                  value={group.uuid}
                                >
                                  {group.name}
                                </option>
                              ))}
                            </SelectInput>
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-4'>
                            <SelectInput
                              label='Idioma'
                              name='lang'
                              className='sort-ordering-select-no-search'
                              value={formik.values.lang}
                              onChange={formik.handleChange}
                              error={formik.errors.lang}
                            >
                              <option value=''>Selecione</option>
                              <option value='pt_br'>Português</option>
                              <option value='en_us'>Inglês</option>
                              <option value='es_es'>Espanhol</option>
                            </SelectInput>
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-4'>
                            <SelectInput
                              label='Tipo'
                              name='quizType'
                              className='sort-ordering-select-no-search'
                              value={formik.values.quizType}
                              onChange={formik.handleChange}
                              error={formik.errors.quizType}
                            >
                              <option value=''>Selecione</option>
                              <option value='technical'>Técnica</option>
                              <option value='behavioral'>Comportamental</option>
                            </SelectInput>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 col-md-6 col-lg-4'>
                            <SelectInput
                              label='Ordenação'
                              name='orderType'
                              className='sort-ordering-select-no-search'
                              value={formik.values.orderType}
                              onChange={formik.handleChange}
                              error={formik.errors.orderType}
                            >
                              <option value=''>Selecione</option>
                              <option value='ordered'>Ordenado</option>
                              <option value='random'>Randômico</option>
                            </SelectInput>
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-4'>
                            <SelectInput
                              label='Mostrar resultados'
                              name='showResults'
                              className='sort-ordering-select-no-search'
                              value={formik.values.showResults}
                              onChange={formik.handleChange}
                              error={formik.errors.showResults}
                            >
                              <option value='true'>Sim</option>
                              <option value='false'>Não</option>
                            </SelectInput>
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-4'>
                            <InputText
                              label='ID Externo'
                              name='externalIds'
                              type='text'
                              value={formik.values.externalIds}
                              onChange={formik.handleChange}
                              error={formik.errors.externalIds}
                            />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-12'>
                            <TextAreaEditableJodit
                              name='instructions'
                              label='Instruções'
                              initialValue={
                                quizData.instructions !== ''
                                  ? quizData.instructions
                                  : ''
                              }
                              onChange={formik.handleChange}
                              error={formik.errors.instructions}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12'>
                            <TextAreaEditableJodit
                              name='finalMessage'
                              label='Mensagem final'
                              weidth={300}
                              initialValue={
                                quizData.final_message !== ''
                                  ? quizData.final_message
                                  : ''
                              }
                              onChange={formik.handleChange}
                              error={formik.errors.finalMessage}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-4 col-sm-4'></div>
                        </div>
                        <div className='row'>
                          <div className='col-md-4'>
                            <SelectInput
                              label='Campos Adicionais'
                              name='numberAdditionalFields'
                              className='sort-ordering-select-no-search'
                              value={formik.values.numberAdditionalFields}
                              onChange={e => handleNumberAdditionalFields(e)}
                              error={formik.errors.numberAdditionalFields}
                            >
                              <option value='0'>Nenhum</option>
                              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                            </SelectInput>
                          </div>
                        </div>
                        {quizData.additional_fields.length > 0 &&
                          quizData.additional_fields.map((ticket, i) => (
                            <div
                              key={i}
                              className='list-group list-group-flush'
                            >
                              <h5 className=''>Campo {i + 1}</h5>
                              <div className='row'>
                                <div className='col-md-3'>
                                  <SelectInput
                                    label='Campo de Integração'
                                    name={`additionalFields.${i}.integration_field`}
                                    className='sort-ordering-select-no-search'
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.additionalFields.length >
                                        0 &&
                                      formik.values.additionalFields[i]
                                        .integration_field
                                    }
                                    error={
                                      formik.errors.additionalFields &&
                                      formik.errors.additionalFields.length >
                                        i &&
                                      formik.errors.additionalFields[i] &&
                                      formik.errors.additionalFields[i]
                                        .integration_field
                                    }
                                  >
                                    <option value='true'>Sim</option>
                                    <option value='false'>Não</option>
                                  </SelectInput>
                                </div>
                                <div className='col-md-3'>
                                  <InputText
                                    label='Identificador'
                                    name={`additionalFields.${i}.id`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.additionalFields.length >
                                        0 &&
                                      formik.values.additionalFields[i].id
                                    }
                                    error={
                                      formik.errors.additionalFields &&
                                      formik.errors.additionalFields.length >
                                        i &&
                                      formik.errors.additionalFields[i] &&
                                      formik.errors.additionalFields[i].id
                                    }
                                    type='text'
                                  />
                                </div>
                                <div className='col-md-3'>
                                  <InputText
                                    label='Nome do Campo'
                                    name={`additionalFields.${i}.label`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.additionalFields.length >
                                        0 &&
                                      formik.values.additionalFields[i].label
                                    }
                                    error={
                                      formik.errors.additionalFields &&
                                      formik.errors.additionalFields.length >
                                        i &&
                                      formik.errors.additionalFields[i] &&
                                      formik.errors.additionalFields[i].label
                                    }
                                    type='text'
                                  />
                                </div>
                                <div className='col-md-3'>
                                  <InputText
                                    label='Instruções'
                                    name={`additionalFields.${i}.instructions`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.additionalFields.length >
                                        0 &&
                                      formik.values.additionalFields[i]
                                        .instructions
                                    }
                                    error={
                                      formik.errors.additionalFields &&
                                      formik.errors.additionalFields.length >
                                        i &&
                                      formik.errors.additionalFields[i] &&
                                      formik.errors.additionalFields[i]
                                        .instructions
                                    }
                                    popoverHelp='Esta mensagem será exibida para auxiliar o candidato no preenchimento do campo.'
                                    type='text'
                                  />
                                </div>
                              </div>
                              <div className='row'>
                                <div className='col-md-3'>
                                  <SelectInput
                                    className='sort-ordering-select-no-search'
                                    label='Regex Validation'
                                    name={`additionalFields.${i}.regex_validation`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.additionalFields.length >
                                        0 &&
                                      formik.values.additionalFields[i]
                                        .regex_validation
                                    }
                                    error={
                                      formik.errors.additionalFields &&
                                      formik.errors.additionalFields.length >
                                        i &&
                                      formik.errors.additionalFields[i] &&
                                      formik.errors.additionalFields[i]
                                        .regex_validation
                                    }
                                  >
                                    <option value=''>Selecione</option>
                                    <option value='^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$'>
                                      CPF
                                    </option>
                                    <option value='^[0-9]*$'>
                                      Somente números
                                    </option>
                                    <option value='^[0-9]{2}$'>
                                      Somente números (2 Digitos)
                                    </option>
                                    <option value='^[0-9]{3}$'>
                                      Somente números (3 Digitos)
                                    </option>
                                    <option value='^[0-9]{4}$'>
                                      Somente números (4 Digitos)
                                    </option>
                                    <option value='^[0-9]{5}$'>
                                      Somente números (5 Digitos)
                                    </option>
                                    <option value='^[0-9]{6}$'>
                                      Somente números (6 Digitos)
                                    </option>
                                    <option value='^[0-9]{7}$'>
                                      Somente números (7 Digitos)
                                    </option>
                                    <option value='^[0-9]{8}$'>
                                      Somente números (8 Digitos)
                                    </option>
                                    <option value='^[0-9]{9}$'>
                                      Somente números (9 Digitos)
                                    </option>
                                    <option value='^[0-9]{10}$'>
                                      Somente números (10 Digitos)
                                    </option>
                                    <option value='^[a-zA-Z]+$'>
                                      Somente letras
                                    </option>
                                    <option value='^[a-zA-Z]{2}$'>
                                      Somente letras (2 Digitos)
                                    </option>
                                    <option value='^[a-zA-Z]{3}$'>
                                      Somente letras (3 Digitos)
                                    </option>
                                    <option value='^[a-zA-Z]{4}$'>
                                      Somente letras (4 Digitos)
                                    </option>
                                    <option value='^[a-zA-Z]{5}$'>
                                      Somente letras (5 Digitos)
                                    </option>
                                    <option value='^[a-zA-Z]{6}$'>
                                      Somente letras (6 Digitos)
                                    </option>
                                    <option value='^[a-zA-Z]{7}$'>
                                      Somente letras (7 Digitos)
                                    </option>
                                    <option value='^[a-zA-Z]{8}$'>
                                      Somente letras (8 Digitos)
                                    </option>
                                    <option value='^[a-zA-Z]{9}$'>
                                      Somente letras (9 Digitos)
                                    </option>
                                    <option value='^[a-zA-Z]{10}$'>
                                      Somente letras (10 Digitos)
                                    </option>
                                    <option value='^.{2}$'>
                                      Qualquer caractere (2 Digitos)
                                    </option>
                                    <option value='^.{3}$'>
                                      Qualquer caractere (3 Digitos)
                                    </option>
                                    <option value='^.{4}$'>
                                      Qualquer caractere (4 Digitos)
                                    </option>
                                    <option value='^.{5}$'>
                                      Qualquer caractere (5 Digitos)
                                    </option>
                                    <option value='^.{6}$'>
                                      Qualquer caractere (6 Digitos)
                                    </option>
                                    <option value='^.{7}$'>
                                      Qualquer caractere (7 Digitos)
                                    </option>
                                    <option value='^.{8}$'>
                                      Qualquer caractere (8 Digitos)
                                    </option>
                                    <option value='^.{9}$'>
                                      Qualquer caractere (9 Digitos)
                                    </option>
                                    <option value='^.{10}$'>
                                      Qualquer caractere (10 Digitos)
                                    </option>
                                  </SelectInput>
                                </div>
                                <div className='col-md-3'>
                                  <SelectInput
                                    className='sort-ordering-select-no-search'
                                    label='Status'
                                    name={`additionalFields.${i}.status`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.additionalFields.length >
                                        0 &&
                                      formik.values.additionalFields[i].status
                                    }
                                    error={
                                      formik.errors.additionalFields &&
                                      formik.errors.additionalFields.length >
                                        i &&
                                      formik.errors.additionalFields[i] &&
                                      formik.errors.additionalFields[i].status
                                    }
                                  >
                                    <option value=''>Selecione</option>
                                    <option value='active'>Ativo</option>
                                    <option value='inactive'>Inativo</option>
                                  </SelectInput>
                                </div>
                                <div className='col-md-3'>
                                  <SelectInput
                                    className='sort-ordering-select-no-search'
                                    label='Obrigatório'
                                    name={`additionalFields.${i}.required`}
                                    onChange={formik.handleChange}
                                    value={
                                      formik.values.additionalFields.length >
                                        0 &&
                                      formik.values.additionalFields[i].required
                                    }
                                    error={
                                      formik.errors.additionalFields &&
                                      formik.errors.additionalFields.length >
                                        i &&
                                      formik.errors.additionalFields[i] &&
                                      formik.errors.additionalFields[i].required
                                    }
                                  >
                                    <option value=''>Selecione</option>
                                    <option value='true'>Sim</option>
                                    <option value='false'>Não</option>
                                  </SelectInput>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <button className='theme-btn' type='submit'>
                  Atualizar
                </button>
              </div>
            </div>
          </form>
          <Dialog
            open={openWarning}
            onClose={handleWarningClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth={true}
            maxWidth='sm'
          >
            <DialogTitle id='alert-dialog-title'>Atenção</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {'Você realmente deseja arquivar essa prova?'}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className='edit-action'>
                <ul className='edit-list'>
                  <li>
                    <button
                      className='theme-btn edit-btn'
                      onClick={handleWarningClose}
                    >
                      cancelar
                    </button>
                  </li>
                  <li>
                    <button
                      className='theme-btn'
                      onClick={handleWarningConfirm}
                    >
                      confirmar
                    </button>
                  </li>
                </ul>
              </div>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}

export default EditQuiz
