import React from 'react'
import {
  makeStyles,
  createTheme,
  ThemeProvider
} from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const theme = createTheme({
  palette: {
    primary: {
      main: '#233d63'
    }
  }
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '457px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'stretch',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}))

export default function CircularIndeterminate() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <CircularProgress size={65} color='primary' />
      </ThemeProvider>
    </div>
  )
}
